import React from 'react';
import css from '../ListingPage.module.css';

const DonationTitle = props => {
  const {
    richTitle
  } = props;

  return (
    <div className={css.sectionHeading}>
      
      <div className={css.heading}>
        <h1 className={css.title}>{richTitle}</h1>
      </div>
    </div>
  );
};

export default DonationTitle;
