import React, { Component } from 'react';
import { array, arrayOf, bool, func, shape, string, oneOf, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { parse } from '../../util/urlHelpers';

import config from '../../config';
import routeConfiguration from '../../routing/routeConfiguration';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_PENDING_APPROVAL, LISTING_STATE_CLOSED, propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';
import { formatMoney, convertMoneyToNumber } from '../../util/currency';
import { createResourceLocatorString, pathByRouteName } from '../../util/routes';

import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { richText } from '../../util/richText';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import {
  sendInvite,
  sendInviteInProgress,
  sendInviteError,
  sendInviteSuccess,
} from '../../ducks/user.duck';

import {
  Page,
  NamedLink,
  NamedRedirect,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  DonatePanel,
  TabNav,
  GoBackLink,
  NotificationBadge,
  Modal,
  SideNavigation,
  AddUserModal,
} from '../../components';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';
import NotFoundPage from '../../containers/NotFoundPage/NotFoundPage';

import { sendEnquiry, setInitialValues, initiateOrder } from './DonationListingPage.duck';
import DonationTitle from './ListingDetails/DonationTitle';

import css from './ListingPage.module.css';

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

const { UUID } = sdkTypes;

const categoryLabel = (categories, key) => {
  const cat = categories.find(c => c.key === key);
  return cat ? cat.label : key;
};

export class DonationListingPageComponent extends Component {
  constructor(props) {
    super(props);
    const { enquiryModalOpenForListingId, params } = props;
    this.state = {
      pageClassNames: [],
      imageCarouselOpen: false,
      enquiryModalOpen: enquiryModalOpenForListingId === params.id,
      isInviteSupplierModalOpen: false,
      isInviteBuyerModalOpen: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onContactUser = this.onContactUser.bind(this);
    this.onSubmitEnquiry = this.onSubmitEnquiry.bind(this);
    this.onOpenSupplierModal = this.onOpenSupplierModal.bind(this);

    this.onCloseSupplierModal = this.onCloseSupplierModal.bind(this);
    this.onOpenBuyerModal = this.onOpenBuyerModal.bind(this);
    this.onCloseBuyerModal = this.onCloseBuyerModal.bind(this);

  }
  // Invoked when a modal is opened from a child component,
  // for example when a filter modal is opened in mobile view
  onOpenSupplierModal() {
    this.setState({ isInviteSupplierModalOpen: true });
  }

  // Invoked when a modal is closed from a child component,
  // for example when a filter modal is opened in mobile view
  onCloseSupplierModal() {
    this.setState({ isInviteSupplierModalOpen: false });
  }

  // Invoked when a modal is opened from a child component,
  // for example when a filter modal is opened in mobile view
  onOpenBuyerModal() {
    this.setState({ isInviteBuyerModalOpen: true });
  }

  // Invoked when a modal is closed from a child component,
  // for example when a filter modal is opened in mobile view
  onCloseBuyerModal() {
    this.setState({ isInviteBuyerModalOpen: false });
  }

  handleSubmit(values) {
    const { history, getListing, params, currentUserActiveBaskets } = this.props;
    const { quantity: quantityRaw, donationItems, ...otherOrderData } = values;
    const listingId = new UUID(params.id);
    const listing = getListing(listingId);

    const initialValues = {
      listing,
      orderData: {
        stockReservationQuantity: Number.parseInt(quantityRaw, 10),
        ...otherOrderData,
      },
      confirmPaymentError: null,
    };

    const { dispatch, onInitiateOrder } = this.props;

    const requestParams = {
      listingId: listing.id,
      quantity: Number.parseInt(quantityRaw, 10),
      donationItems,
    };
    const transactionIdMaybe = !!currentUserActiveBaskets[listing.author.id.uuid]
      ? currentUserActiveBaskets[listing.author.id.uuid]
      : null;

    onInitiateOrder(requestParams, transactionIdMaybe).then(params => {
      const routes = routeConfiguration();
      const orderDetailsPath = pathByRouteName('OfferedDonationPage', routes, {
        id: params.id.uuid,
      });
      history.push(orderDetailsPath);
    });
  }

  onContactUser() {
    const { currentUser, history, callSetInitialValues, params, location } = this.props;

    if (!currentUser) {
      const state = {
        from: `${location.pathname}${location.search}${location.hash}`,
      };

      // We need to log in before showing the modal, but first we need to ensure
      // that modal does open when user is redirected back to this listingpage
      callSetInitialValues(setInitialValues, {
        enquiryModalOpenForListingId: params.id,
      });

      // signup and return back to listingPage.
      history.push(createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}), state);
    } else {
      this.setState({ enquiryModalOpen: true });
    }
  }

  onSubmitEnquiry(values) {
    const { history, params, onSendEnquiry } = this.props;
    const routes = routeConfiguration();
    const listingId = new UUID(params.id);
    const { message } = values;

    onSendEnquiry(listingId, message.trim())
      .then(txId => {
        this.setState({ enquiryModalOpen: false });

        // Redirect to OfferedDonationPage
        history.push(
          createResourceLocatorString('OfferedDonationPage', routes, { id: txId.uuid }, {})
        );
      })
      .catch(() => {
        // Ignore, error handling in duck file
      });
  }

  render() {
    const {
      unitType,
      isAuthenticated,
      currentUser,
      currentUserPermissions,
      providerNotificationCount,
      currentUserActiveBaskets,
      getListing,
      getOwnListing,
      intl,
      onManageDisableScrolling,
      params: rawParams,
      location,
      scrollingDisabled,
      showListingError,
      reviews,
      fetchReviewsError,
      sendEnquiryInProgress,
      sendEnquiryError,
      timeSlots,
      fetchTimeSlotsError,
      customConfig,
      fetchLineItemsInProgress,
      fetchLineItemsError,
      showPrice,
      onSendInvite,
      sendInviteError,
      sendInviteInProgress,
      sendInviteSuccess,
      inviteHasAlreadySentError,
    } = this.props;

    const listingId = new UUID(rawParams.id);
    const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
    const currentListing =
      isPendingApprovalVariant || isDraftVariant
        ? ensureOwnListing(getOwnListing(listingId))
        : ensureListing(getListing(listingId));

    const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
    const params = { slug: listingSlug, ...rawParams };

    const listingType = isDraftVariant
      ? LISTING_PAGE_PARAM_TYPE_DRAFT
      : LISTING_PAGE_PARAM_TYPE_EDIT;
    const listingTab = isDraftVariant ? 'photos' : 'details';

    const isApproved =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

    const addUserModal = (
      <AddUserModal
        setIsInviteSupplierModalClosed={this.onCloseSupplierModal}
        isInviteSupplierModalOpen={this.state.isInviteSupplierModalOpen}

        setIsInviteBuyerModalClosed={this.onCloseBuyerModal}
        isInviteBuyerModalOpen={this.state.isInviteBuyerModalOpen}
        onManageDisableScrolling={onManageDisableScrolling}
        currentUser={currentUser}
        onSendInvite={onSendInvite}
        sendInviteInProgress={sendInviteInProgress}
        sendInviteError={sendInviteError}
        sendInviteSuccess={sendInviteSuccess}
        inviteHasAlreadySentError={inviteHasAlreadySentError}
      />
    );
    const modals = (
      <div>
        {/* <Modal
                {...props}
                isOpen={isInviteSupplierModalOpenFORM}
                onClose={() => {
                  setIsInviteSupplierModalOpen(false);
                }}
                onManageDisableScrolling={onManageDisableScrolling}
                id="inviteSupplier"
              >
                <InviteSupplierForm
                  className={css.form}
                  currentUser={currentUser}
                  // initialValues={{ firstName, lastName, bio, profileImage: user.profileImage }}
                  // profileImage={profileImage}
                  // onImageUpload={e => onImageUploadHandler(e, onImageUpload)}
                  // uploadInProgress={uploadInProgress}
                  // updateInProgress={updateInProgress}
                  // uploadImageError={uploadImageError}
                  // updateProfileError={updateProfileError}
                  onSubmit={onSendInvite}
                />
              </Modal> */}
      </div>
    );
    const pendingIsApproved = isPendingApprovalVariant && isApproved;
    const currentUserAttributes = currentUser ? currentUser.attributes : {};
    const ordersOnHold = currentUserAttributes.profile
      ? currentUserAttributes.profile.privateData.ordersOnHold || []
      : [];

    // If a /pending-approval URL is shared, the UI requires
    // authentication and attempts to fetch the listing from own
    // listings. This will fail with 403 Forbidden if the author is
    // another user. We use this information to try to fetch the
    // public listing.
    const pendingOtherUsersListing =
      (isPendingApprovalVariant || isDraftVariant) &&
      showListingError &&
      showListingError.status === 403;
    const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

    if (shouldShowPublicListingPage) {
      return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
    }

    const {
      geolocation = null,
      price = null,
      title = '',
      publicData,
      pricePerCase,
      pricePerKG,
      pricePerUnit,
    } = currentListing.attributes;

    const searchPageDescription = !!publicData?.searchPageDescription
      ? publicData.searchPageDescription
      : '';
    const description =
      searchPageDescription != '' ? searchPageDescription : currentListing.attributes.description;

    const pricing = publicData.pricing;

    const richTitle = (
      <span>
        {richText(title, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
          longWordClass: css.longWord,
        })}
      </span>
    );
    const bookingTitle = (
      <FormattedMessage id="ListingPage.bookingTitle" values={{ title: richTitle }} />
    );

    const topbar = <TopbarContainer />;

    if (showListingError && showListingError.status === 404) {
      // 404 listing not found

      return <NotFoundPage />;
    } else if (showListingError) {
      // Other error in fetching listing

      const errorTitle = intl.formatMessage({
        id: 'ListingPage.errorLoadingListingTitle',
      });

      return (
        <Page title={errorTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.errorText}>
                <FormattedMessage id="ListingPage.errorLoadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    } else if (!currentListing.id) {
      // Still loading the listing

      const loadingTitle = intl.formatMessage({
        id: 'ListingPage.loadingListingTitle',
      });

      return (
        <Page title={loadingTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.loadingText}>
                <FormattedMessage id="ListingPage.loadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    }

    const handleViewPhotosClick = e => {
      // Stop event from bubbling up to prevent image click handler
      // trying to open the carousel as well.
      e.stopPropagation();
      this.setState({
        imageCarouselOpen: true,
      });
    };
    const authorAvailable = currentListing && currentListing.author;
    const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
    const isOwnListing =
      userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;
    const showContactUser = false;
    //    authorAvailable && (!currentUser || (currentUser && !isOwnListing));

    const currentAuthor = authorAvailable ? currentListing.author : null;
    const ensuredAuthor = ensureUser(currentAuthor);

    // When user is banned or deleted the listing is also deleted.
    // Because listing can be never showed with banned or deleted user we don't have to provide
    // banned or deleted display names for the function
    const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

    const handleOrderSubmit = values => {
      const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;
      if (isOwnListing || isCurrentlyClosed) {
        window.scrollTo(0, 0);
      } else {
        this.handleSubmit(values);
      }
    };

    const listingImages = (listing, variantName) =>
      (listing.images || [])
        .map(image => {
          const variants = image.attributes.variants;
          const variant = variants ? variants[variantName] : null;

          // deprecated
          // for backwards combatility only
          const sizes = image.attributes.sizes;
          const size = sizes ? sizes.find(i => i.name === variantName) : null;

          return variant || size;
        })
        .filter(variant => variant != null);

    const schemaImages = listingImages(currentListing, `${config.listing.variantPrefix}-2x`).map(
      img => img.url
    );
    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage(
      { id: 'DonationPage.schemaTitle' },
      { title, siteTitle }
    );
    // You could add reviews, sku, etc. into page schema
    // Read more about product schema
    // https://developers.google.com/search/docs/advanced/structured-data/product
    const productURL = `${config.canonicalRootURL}${location.pathname}${location.search}${location.hash}`;
    const brand = currentListing?.attributes?.publicData?.brand;
    const brandMaybe = brand ? { brand: { '@type': 'Brand', name: brand } } : {};

    const currentStock = currentListing.currentStock?.attributes?.quantity || 0;
    const schemaAvailability =
      currentStock > 0 ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock';

    const authorLink = (
      <NamedLink
        className={css.authorNameLink}
        name="ListingPage"
        params={params}
        to={{ hash: '#author' }}
      >
        {authorDisplayName}
      </NamedLink>
    );

    const isKeywordSearch = false;

    const topbarClasses = this.state.isMobileModalOpen
      ? classNames(css.topbarBehindModal, css.topbar)
      : css.topbar;
    const onHoldOrdersCount = ordersOnHold ? ordersOnHold.length : null;

    const { tab } = params;

    const isOrders = tab === 'orders';
    const isSuppliers = tab === 'suppliers';
    const isCustomers = tab === 'customers';
    const isSales = tab === 'sales';
    const isCreateListing = tab === 'create-listing';
    const isYourListings = tab === 'your-listing';
    const activeTab = tab;

    // START SIDE NAV
    const nav = (
      <SideNavigation
        currentUserPermissions={currentUserPermissions}
        ordersOnHold={ordersOnHold}
        isSuppliers={isSuppliers}
        isOrders={isOrders}
        isCustomers={isCustomers}
        isSales={isSales}
        providerNotificationCount={providerNotificationCount}
        isYourListings={isYourListings}
        setIsInviteSupplierModalOpen={this.onOpenSupplierModal}
        setIsInviteBuyerModalOpen={this.onOpenBuyerModal}
      />
    );
    // END SIDE NAV

    return (
      <Page
        title={schemaTitle}
        scrollingDisabled={scrollingDisabled}
        author={authorDisplayName}
        contentType="website"
        description={description}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'Product',
          description: description,
          name: schemaTitle,
          image: schemaImages,
          ...brandMaybe,
          offers: {
            '@type': 'Offer',
            url: productURL,

            availability: schemaAvailability,
          },
        }}
      >
        <TopbarContainer className={topbarClasses} currentPage="SearchPage" />

        <div className={css.layoutWrapperContainer}>
          <aside className={css.layoutWrapperFilterColumn} data-cy="asideSideNav">
            <GoBackLink></GoBackLink>
            {nav}
          </aside>
          <div className={css.layoutWrapperMain} role="main">
            <div className={css.contentWrapperForProductLayout}>
              <div className={css.mainColumnForProductLayout}>
                <DonationTitle richTitle={richTitle} />

                <DonatePanel
                  className={css.donationOrderPanel}
                  listing={currentListing}
                  isOwnListing={isOwnListing}
                  unitType={unitType}
                  onSubmit={handleOrderSubmit}
                  title={bookingTitle}
                  author={ensuredAuthor}
                  onManageDisableScrolling={onManageDisableScrolling}
                  onContactUser={this.onContactUser}
                  timeSlots={timeSlots}
                  fetchTimeSlotsError={fetchTimeSlotsError}
                  fetchLineItemsInProgress={fetchLineItemsInProgress}
                  fetchLineItemsError={fetchLineItemsError}
                  showPrice={showPrice}
                  pricing={pricing}
                  description={description}
                />
              </div>
            </div>
          </div>
        </div>
        {addUserModal}
        {modals}
      </Page>
    );
  }
}

DonationListingPageComponent.defaultProps = {
  unitType: null,
  currentUser: null,
  currentUserPermissions: null,
  providerNotificationCount: null,
  currentUserActiveBaskets: null,
  enquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  sendEnquiryError: null,
  customConfig: config.custom,
  fetchLineItemsError: null,
  showPrice: false,
  filterConfig: config.custom.filters,
};

DonationListingPageComponent.propTypes = {
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  unitType: propTypes.lineItemUnitType,
  // from injectIntl
  intl: intlShape.isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  enquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  sendEnquiryInProgress: bool.isRequired,
  sendEnquiryError: propTypes.error,
  onSendEnquiry: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  customConfig: object,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  filterConfig: propTypes.filterConfig,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const {
    showListingError,
    reviews,
    fetchReviewsError,
    timeSlots,
    fetchTimeSlotsError,
    sendEnquiryInProgress,
    sendEnquiryError,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    enquiryModalOpenForListingId,
    initiateOrderError,
    showPrice,
  } = state.ListingPage;
  const {
    currentUser,
    currentUserActiveBaskets,
    currentUserPermissions,
    providerNotificationCount,
    sendInviteError,
    sendInviteInProgress,
    sendInviteSuccess,
    inviteHasAlreadySentError,
  } = state.user;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isAuthenticated,
    currentUser,
    currentUserPermissions,
    providerNotificationCount,
    currentUserActiveBaskets,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    enquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    timeSlots,
    fetchTimeSlotsError,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendEnquiryInProgress,
    sendEnquiryError,
    initiateOrderError,
    showPrice,

    sendInviteError,
    sendInviteInProgress,
    sendInviteSuccess,
    inviteHasAlreadySentError,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) => {
    dispatch(setInitialValues(values, saveToSessionStorage));
  },
  onInitiateOrder: (params, transactionId) => dispatch(initiateOrder(params, transactionId)),
  onSendEnquiry: (listingId, message) => dispatch(sendEnquiry(listingId, message)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onSendInvite: body => {
    dispatch(sendInvite(body));
  },
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const DonationListingPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(DonationListingPageComponent);

export default DonationListingPage;
